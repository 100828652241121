import {Message,Loading} from 'element-ui';
import {axiosInstance} from "@/axios"//axios全局配置
const exportExcel = (url, params={},type='get')=>{
      let loading=Loading.service({ fullscreen: true });
      let req='';
      if(type=='get'){
        req= axiosInstance.get(url,
            { params:params, responseType: 'arraybuffer'}
        )
      }else {
        req= axiosInstance({url,method:type, data:params, responseType: 'arraybuffer'})
      }
     req
    .then((res)=>{
      loading.close()
      Message.closeAll()
      //console.log("返回的二进制流文件", res )
      if(!res) {
        Message({message: '数据获取失败，请稍后再试',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      //fileName:下载的文件名
      var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'});
   
      // 针对ie浏览器
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        //非ie浏览器
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //常见下载的链接
        downloadElement.href = href;
        downloadElement.download = fileName; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
      }
    }).catch((error)=>{
      loading.close()
      console.log(error);
    })
  }
   
  export default exportExcel